.login-section {
  height: 100vh;
  .login-content {
    padding: 14% 14% 14% 13%;
    position: relative;
    height: 100%;

    h3 {
      font-size: 24px;
      font-weight: 700;
      margin: 40px 0px;
    }

    .login-lable {
      font-weight: 600;
      color: #000;
    }

    .new-lable {
      font-weight: 600;
      margin: 26px 0;
      color: #000;
    }

    .login-input {
      padding: 12px 18px;
      font-weight: 500;
    }

    form {
      button.btn.btn-primary2 {
        width: 100%;
        background: #216fb8;
        font-weight: 500;
        font-size: 20px;
        padding: 9px 10px;
        margin-top: 15px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .branding {
      right: 0;
      position: absolute;
      bottom: 0px;
      left: 0;

      h4 {
        font-size: 14px;
        color: #9c9c9c;
        text-align: center;
      }

      h5 {
        font-size: 16px;
        text-align: center;
        color: #9c9c9c;
      }
    }
  }

  .login-img {
    background: #ffffff;
    text-align: center;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      position: absolute;
      top: 20%;
      right: 22%;
      color: #c6daee;
      text-align: end;
      font-size: 31px;
      font-weight: 600;
    }

    img {
      max-width: 90% !important;
      border-radius: 20px;
    }
  }

  .otp {
    input {
      padding: 12px 0px;
      font-weight: 500;
      width: 9.5% !important;
      border-radius: 7px;
      border: 1px solid #d5d5d5;
      display: flex;
    }

    > div {
      justify-content: space-between !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .loginBannerImage {
    // display: none;
  }
  .login-section .login-content {
    padding: 0% 10% 14% 10%;
    position: relative;
    height: 100%;
    justify-content: flex-start !important;
    .logo {
      display: none;
    }
    h3 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
      margin-top: 0px;
    }
  }
}
