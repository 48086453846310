@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

.themePrimary {
  background-color: #33479e;
}

.btn-primary2 {
  width: 100%;
  background: #216fb8;
  font-weight: 500;
  font-size: 20px;
  padding: 9px 10px;
  margin-top: 15px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* a {
  text-decoration: none !important;
  color: #fff !important;
} */

.searchBtn {
  font-size: 1.6rem;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.rs-container {
  height: 100vh;
}

.rs-sidebar {
  height: 100vh;
  position: absolute;
  z-index: 9999;
  overflow: hidden;
  box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
}

.rs-sidenav-header {
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.rs-sidenav {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
  /* background: #44536b !important; */
  background: #fff !important;
}

.rs-sidenav-item .rs-sidenav-item-icon {
  margin-right: 20px;
  position: absolute;
  left: 20px;
  top: 16px;
  line-height: 1.25;
  height: 20px;
  width: 20px;
}

.rs-sidenav::-webkit-scrollbar {
  display: none;
}

.rs-sidenav-item:hover {
  background: #33479e !important;
  color: #fff !important;
}

.rs-sidenav-item:hover a {
  color: #fff !important;
}

.rs-dropdown-item:hover {
  background: #33479e !important;
  color: #fff !important;
}

.rs-dropdown-item:hover a {
  /* background: #33479e !important; */
  color: #fff !important;
}

.rs-table {
  width: 100% !important;
}

.rs-table-header-row-wrapper {
  z-index: 0 !important;
}

/* .rs-dropdown-open {
  z-index: 3;
} */

.rs-sidenav-item-active {
  background: #33479e !important;
  color: #fff !important;
}

.nav-toggle {
  box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
}

#sidebarHeader {
  height: 7vh;
}

.rs-header {
  height: 7vh;
  margin-left: 55px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 10%);
  z-index: 1;
  background: #33479e;
  color: white;
}

.rs-header h4 {
  font-weight: 200;
  font-size: 20px;
  line-height: 34px;
}

.rs-content {
  background: #f2f2f5;
  width: calc(100vw - 58px);
  margin-left: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.rs-dropdown-toggle {
  background: #44536b;
  color: #fff;
}

.rs-sidenav-nav a {
  color: #8e8e93 !important;
  text-decoration: none !important;
  /* margin: 0 15px; */
}

/* button.btn.btn-sm.btn-danger.ms-2 {
  background: #2677c4;
  border: 0;
}

button.btn.btn-danger {
  border: 0;
  background: #f31606;
} */

button.btn.btn-danger:hover {
  background: #000;
}

/* TABLE STYLING */
.rs-table-body-wheel-area {
  background-color: #fff !important;
}

/* LOGIN PAGE STYLE */

.loginContainer {
  background-color: #33479e;
}

.loginDiv {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  min-width: 350px;
}

/* MODAL STYLING */
.rs-modal-body {
  overflow-x: hidden !important;
  padding-bottom: 0 !important;
}

.mydiv {
  background-color: red;
}

/* SHOP TABLE STYLING */
.shopTable .rs-table-cell-content {
  padding: 7px 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopTable {
  overflow: hidden;
  background: #fff;
}

.rs-pagination-group {
  width: 100% !important;
}

.rs-pagination-group-xs {
  font-size: 12px;
  padding: 8px !important;
}

.ProductImageDiv {
  padding: 2rem;
  border: 2px solid #ddd;
  border-radius: 2rem;
  margin: 0px 0;
  font-size: 2rem;
  position: relative;
}

.imageDeleteBtn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.5rem;
  color: #fff;
  border-radius: 50%;
  background: red;
  padding: 0px 6px;
  cursor: pointer;
}

.imageDiv {
  position: relative;
  width: 285px;
}

.shopImageDiv {
  position: relative;
  width: 327px;
}

.logoDiv {
  position: relative;
  width: 265px;
}

.labelBold {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.imageEdit {
  position: absolute;
  right: 5px;
  top: 5px;
  background: #fff;
  padding: 3px 8px 6px 8px;
  border-radius: 5px;
  cursor: pointer;
}

.vh80 {
  height: 80vh;
}

.vh82 {
  height: 82vh;
}

.vh83 {
  height: 83vh;
}

.vh77 {
  height: 77vh;
}

.vh78 {
  height: 78vh;
}

.vh75 {
  height: 75vh;
}

.vh73 {
  height: 73vh;
}

.vh72 {
  height: 72vh;
}

.scrolly {
  overflow-y: scroll;
}

.vh90 {
  height: 90vh;
}

.vh85 {
  height: 85vh;
}

.addHomeSlider .rs-picker-toggle-wrapper {
  width: 100% !important;
}

.cropModalContainer {
  height: 300px;
  position: relative;
}

.tablePagination {
  border-radius: 5px 5px 0 0;
}

.tableWithoutPagination {
  border-radius: 5px;
}

.rs-table-cell-content {
  display: flex !important;
  justify-content: left !important;
  align-items: center !important;
}

.paginationStrip {
  border-radius: 0 0 5px 5px;
  background-color: #fff;
}

.mobilePaginationStrip {
  display: none;
}

.serviceImagePreview {
  border-radius: 10px;
  overflow: hidden;
}

.imagePreview {
  width: 317px;
  height: 178px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.imagePreviewSquare {
  /* width: 200px;
  height: 200px; */
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.imagePreview img,
.imagePreviewSquare img {
  width: 100%;
  height: 100%;
}

.editIcon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  background: #fff;
  border-radius: 5px;
}

.uploadImageBox {
  /* width: 317px;
  height: 178px; */
  border: 0.5px dotted #ddd;
  border-radius: 10px;
  cursor: pointer;
}

.uploadImageBoxSquare {
  /* width: 200px;
  height: 200px; */
  border: 0.5px dotted #ddd;
  border-radius: 10px;
  cursor: pointer;
}

#addShopForm .rs-input {
  padding: 10px 11px !important;
}

#addShopForm .rs-picker {
  width: 100% !important;
}

#addShopForm .rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 10px;
  padding-bottom: 10px;
}
#addShopForm
  .rs-picker-default
  .rs-picker-toggle.rs-btn
  .rs-picker-toggle-caret {
  top: 10px;
}

#addShopForm .col-md-4 {
  margin-bottom: 25px;
}

.rs-table-row-header {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.rs-modal-wrapper {
  display: flex !important;
  align-items: center !important;
}

.rs-icon {
  font-weight: 700;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
}
/* .paginationStrip .rs-pagination-btn.rs-pagination-btn-active {
  color: #1675e0;
  color: #fff !important;
  background-color: #dc3545;
  border: 1px solid #1675e0;
  border: 1px solid #fff !important;
} */

.rs-sidenav-item-panel {
  padding-left: 20px !important;
  font-weight: 500 !important;
  color: #8e8e93 !important;
  margin: 10px 0 !important;
}

.uploadProductImageBox {
  width: 200px;
  height: 200px;
  border: 0.5px dotted #ddd;
  border-radius: 10px;
  cursor: pointer;
}

.actionCell .rs-table-cell-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerCell .rs-table-cell-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.rs-table-cell-header .rs-table-cell-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* .rs-table-cell-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
} */

.socialMediaDiv {
  height: 203px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.socialIcon {
  font-size: 2rem;
}

.borderBottomLight {
  border-bottom: 1px solid #ddd;
}

.buyTypeRadioGroup {
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
}

/* .buyTypeRadioGroup label {
  cursor: pointer;
  padding: 7px;
} */

.buyTypeRadioGroup label#bT2 {
  display: flex;
  justify-content: center;
  padding: 7px;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  border-radius: 5px 0 0 5px;
  color: #000;
  background-origin: border-box;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  width: 50%;
  font-weight: 600;
}

.input-group-text {
  border-radius: 5px 0 0 5px !important;
  height: 100% !important;
}

.buyTypeRadioGroup label#bT1 {
  display: flex;
  justify-content: center;
  padding: 7px;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  border-radius: 0 5px 5px 0;
  color: #000;
  background-origin: border-box;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  width: 50%;
  font-weight: 600;
}

.buyTypeRadioGroup label.active {
  background: linear-gradient(180deg, #4b91f7 0%, #367af6 100%);
  color: #fff !important;
}

.rs-table-cell-content {
  padding: 7px 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .mainContent {
  padding: 10px;
} */

/* MOBILE CSS */
.mobileTable {
  font-size: 12px;
}
.mobileAction {
  display: flex;
  align-items: center;
}
.mobileActionBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a58ca;
  border-radius: 2px;
  border: none;
  color: #ffffff;
  font-size: 15px !important;
  padding: 0 10px;
  user-select: none;
  margin-right: 5px;
}

.filterDrawer .rs-drawer-header {
  padding: 5px 20px 5px 54px !important;
}

.filterDrawer .rs-drawer-header-close {
  top: 8px !important;
}

/* Chrome, Safari, Edge, Opera */
.otpInput::-webkit-outer-spin-button,
.otpInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otpInput {
  -moz-appearance: textfield;
}

@media (max-width: 920px) {
  .rs-content {
    width: 100vw;
    padding: 0px !important;
  }
  .rs-header {
    margin-left: 0px;
    z-index: 10;
  }
  .rs-header {
    height: 6vh !important;
  }
  .rs-sidebar {
    margin-top: 6vh;
    height: 94vh;
    position: absolute;
    z-index: 9999;
    overflow: hidden;
    box-shadow: 2px 0 10px -2px rgba(0, 0, 0, 0.5);
  }
  .rs-sidenav-item .rs-sidenav-item-icon {
    top: 25px;
  }
  #sidebarHeader {
    display: none !important;
  }
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-nav > .rs-sidenav-item {
    line-height: 2.25 !important;
  }

  .rs-sidenav-nav
    > .rs-dropdown
    .rs-dropdown-toggle
    > .rs-icon:not(.rs-dropdown-toggle-caret),
  .rs-sidenav-nav > .rs-sidenav-item > .rs-icon:not(.rs-dropdown-toggle-caret) {
    top: 22px !important;
  }

  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
    top: 22px !important;
  }

  .rs-dropdown-menu.rs-dropdown-menu-collapse-in li {
    background-color: #1675e01f;
  }
  .demandSection table tr {
    font-size: 12px;
  }
  .overlay {
    display: block;
  }
  .rs-sidebar {
    display: none !important;
  }
  .ct-group {
    margin: 20px 20px !important;
  }
  .rs-header {
    padding: 0;
  }
  .logoutDesktop {
    display: none;
  }
  .mobilePaginationStrip {
    display: block;
  }
}

.brandSelectMenu {
  padding: 0 !important;
}

.brandSelectMenu .rs-picker-list-item:last-child {
  background-color: #4288f7;
}
.brandSelectMenu .rs-picker-list-item:last-child .rs-picker-select-menu-item {
  color: #fff !important;
}

.brandSelectMenu
  .rs-picker-list-item:last-child
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  color: #000 !important;
}

.notificationBell .rs-popover.placement-bottom-end {
  left: 335px !important;
}

/* MOBILE CSS */
.mobilePaginationStrip {
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100vw;
}
.mobilePaginationStrip .rs-pagination-xs .rs-pagination-btn {
  color: #000;
}
.overlay {
  display: none;
}
/* MOBILE BOTTOM NAVIGATION */
.bottonNavigation {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 7vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  z-index: 9999;
}
.bottonNavigation .tabDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bottonNavigation .tabDiv .tabName {
  font-size: 10px;
  font-weight: 600;
  margin-top: 4px;
}
.bottonNavigation .tabDiv .activeTab {
  color: #33479e;
}
/* MOBILE BUTTON CSS */
.notificationButton {
  background-color: transparent !important;
  background: transparent !important;
}
.notificationButton:hover {
  background-color: transparent !important;
  background: transparent !important;
}
/* COUNTER SALE CSS */
.counterSaleProductBox {
  position: relative;
}
.counterAccordion {
  .rs-panel-header {
    background: #33479e !important;
    color: #ffffff !important;
    padding: 10px !important;
  }
  .rs-panel-btn-icon.rs-icon {
    top: 11px !important;
    right: 15px !important;
    color: #ffffff !important;
  }
  .rs-panel-body {
    background-color: #ffffff !important;
    padding: 4px !important;
  }
  .rs-panel-btn:hover {
    background-color: transparent !important;
    color: #fff !important;
  }
}
.userGuideAccordion {
  .rs-panel-btn {
    background: #374699 !important;
    color: #ffffff !important;
  }
}
.selectPickerCss .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 7px !important;
  right: 12px !important;
}
.rs-pagination-symbol .rs-icon {
  color: #000000 !important;
}
.checkout {
  position: absolute;
  bottom: 0;
  width: 100vw !important;
  -webkit-box-shadow: 0px -1px 10px rgba(50, 50, 50, 0.2);
  -moz-box-shadow: 0px -1px 10px rgba(50, 50, 50, 0.2);
  box-shadow: 0px -1px 10px rgba(50, 50, 50, 0.2);
}
.manage-section .rs-panel-body {
  padding: 10px !important;
}
.shopProfileLinks {
  border-radius: 0px !important;
  border-bottom: 1px solid #ddd !important;
}
.individualLink {
  font-size: 16px !important;
  color: #000 !important;
  padding: 11px 8px;
}
.shopProfileLinks .rs-panel-header {
  padding: 12px 8px !important;
  color: #000 !important;
}
.shopProfileLinks .rs-panel-body {
  padding: 5px 8px !important;
  font-size: 16px !important;
  color: #000 !important;
}
.shopProfileLinks .rs-panel-btn {
  padding: 0px !important;
}
.shopProfileLinks .rs-panel-btn:active,
.shopProfileLinks .rs-panel-btn:focus,
.shopProfileLinks .rs-panel-btn:hover {
  background-color: #fff !important;
}
.shopProfileLinks .rs-panel-collapsible > .rs-panel-header > .rs-icon {
  top: 10px !important;
  color: #000;
  right: 10px;
}
.transactionTabs .rs-nav-horizontal {
  display: flex;
  justify-content: space-around;
}
.transactionPanel .rs-panel-body {
  padding: 0 !important;
}
.gCoin {
  border: 1px solid;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #dcba00;
  color: #fff;
  font-size: 16px;
}
.gstPanel .rs-panel-btn:active,
.gstPanel .rs-panel-btn:focus,
.gstPanel .rs-panel-btn:hover {
  background-color: #fff !important;
}
.gstPanel .rs-panel-btn-icon.rs-icon {
  display: none !important;
}
.gstUpdateButton {
  position: absolute;
  bottom: 0px;
  width: 100vw;
}
.orderFilterDrawer .rs-drawer-body {
  padding: 15px 10px !important;
}
.orderFilterDrawer {
  height: 210px !important;
  .rs-drawer-body {
    padding: 15px 10px !important;
    overflow: visible;
  }
}
.addToCart {
  position: absolute;
  bottom: 5px;
  right: 5px;
  .rs-btn {
    font-size: 12px !important;
    padding: 3px 8px !important;
  }
}

.priceInput {
  .rs-input-group-addon {
    padding: 6px 12px !important;
  }
}

.addToCartBottom {
  .rs-btn {
    font-size: 12px !important;
    padding: 3px 8px !important;
    border: 1px solid #e5e5ea !important;
  }
}
#qrCodeModal {
  width: 330px !important;
}
.userGuideAccordion {
  iframe {
    width: 100% !important;
  }
}

.posAddress {
  .rs-stack-item:not(.rs-radio-tile-icon) {
    width: 100% !important;
  }
}
